


















































































































































































































































































































































import Vue from "vue";
import GalleryComponent from "@/components/home/GalleryComponent.vue";
import HomeHeader from "../components/home/HomeHeader.vue";

export default Vue.extend({
  name: "HomeView",

  components: {
    HomeHeader,
    GalleryComponent,
  },
  mounted() {
    setInterval(() => {
      if (this.advertisementParallax < 2) {
        this.advertisementParallax++;
      } else {
        this.advertisementParallax = 0;
      }
    }, 5000);
  },
  data: () => ({
    advertisementParallax: 0,
    timer: undefined,
    socialMediaIcons: [
      {
        icon: "mdi-instagram",
        link: "https://www.instagram.com/omegavr.de/",
      },
      {
        icon: "$tiktok",
        link: "https://www.tiktok.com/@omegavr",
      },
      {
        icon: "mdi-facebook",
        link: "https://www.facebook.com/Omega-Virtual-Reality-103708545829435",
      },
    ],
    images: [
      require("@/assets/webp/rlFootageHumanNormalized.webp"),
      require("@/assets/webp/games/CyberShockB.webp"),
      require("@/assets/webp/games/DeadAheadA.webp"),
      require("@/assets/webp/games/DeadAheadD.webp"),
      require("@/assets/webp/games/DeadAheadC.webp"),
      require("@/assets/webp/games/ArrowSongD.webp"),
      require("@/assets/webp/games/QuantumArenaD.webp"),
      require("@/assets/webp/games/CopsvsRobbersA.webp"),
    ],
    testimonials: [
      {
        name: "Thomas Müller",
        type: "Firmenveranstaltung",
        text: "Das OmegaVR-Team hat unseren Teambuilding-Tag zu einem unvergesslichen Erlebnis gemacht. Die Mitarbeiter sprechen noch Wochen später davon!",
        rating: 5,
        author: "Thomas Müller",
        event: "Firmenveranstaltung",
      },
      {
        name: "Julia Schmidt",
        type: "Kindergeburtstag",
        text: "Mein Sohn und seine Freunde waren begeistert! Die professionelle Betreuung und die große Spielauswahl haben alle überzeugt. Absolut empfehlenswert!",
        rating: 5,
        author: "Julia Schmidt",
        event: "Kindergeburtstag",
      },
      {
        name: "Michael Weber",
        type: "Privatveranstaltung",
        text: "Die Flexibilität und der Service waren erstklassig. Das mobile Setup wurde schnell aufgebaut und alles lief reibungslos. Wir werden OmegaVR definitiv wieder buchen!",
        rating: 5,
        author: "Michael Weber",
        event: "Privatveranstaltung",
      },
    ],
    faqs: [
      {
        question: "Wie viel Platz wird für das VR-Setup benötigt?",
        answer:
          "Für unser Erlebnis benötigen Sie eine Fläche von mindestens 5 x 5 Metern. Es sind Spielfelder bis zu 6 x 9 Metern möglich. Für ein optimales Erlebnis sollte der Boden eben sein und es dürfen sich keine Hindernisse im Spielfeld befinden!",
      },
      {
        question: "Für welche Anlässe kann ich OmegaVR buchen?",
        answer:
          "OmegaVR eignet sich für zahlreiche Anlässe: Firmenevents, Teambuilding, Geburtstage, Hochzeiten, Schulveranstaltungen und private Feiern. Kontaktieren Sie uns für individuelle Lösungen!",
      },
      {
        question: "Wie lange im Voraus sollte ich buchen?",
        answer:
          "Wir empfehlen, mindestens 2-3 Wochen im Voraus zu buchen, besonders für Wochenendtermine. Für kurzfristige Anfragen kontaktieren Sie uns bitte direkt - manchmal haben wir noch freie Termine!",
      },
    ],
  }),
  methods: {
    getCarouselHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 250;
        case "sm":
          return 350;
        case "md":
          return 450;
        case "lg":
          return 500;
        case "xl":
          return 650;
      }
    },
  },
});
