import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";
import goTo from "vuetify/lib/services/goto";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/pricing",
    name: "pricing",
    component: () => import("@/views/PricingView.vue"),
  },
  {
    path: "/booking",
    name: "booking",
    component: () => import("@/views/BookingView.vue"),
  },
  {
    path: "/vr/games",
    name: "vr_games",
    component: () => import("@/views/VrGamesView.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("@/views/FAQView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/AboutView.vue"),
  },
  {
    path: "/privacy_policy",
    name: "privacy_policy",
    component: () => import("@/views/PrivacyPolicyView.vue"),
  },
  {
    path: "/imprint",
    name: "imprint",
    component: () => import("@/views/ImprintView.vue"),
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo: number | string = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
});

export default router;
