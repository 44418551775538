<template>
  <div>
    <v-app-bar app color="transparent" elevation="0">
      <router-link to="/#top" class="nav-img">
        <v-img max-width="200px" src="@/assets/webp/logo.webp"></v-img>
      </router-link>

      <v-spacer />

      <div v-if="!showMenuIcon">
        <v-btn
          active-class="nav-active"
          class="nav-button"
          large
          link
          plain
          to="/#top"
        >
          Startseite
        </v-btn>
        <v-btn
          active-class="nav-active"
          class="nav-button"
          large
          link
          plain
          to="/vr/games"
        >
          VR Games
        </v-btn>

        <v-btn
          active-class="nav-active"
          class="nav-button"
          large
          link
          plain
          to="/pricing"
        >
          Preise
        </v-btn>

        <v-btn
          active-class="nav-active"
          class="nav-button"
          large
          link
          plain
          to="/about"
        >
          Über uns
        </v-btn>

        <v-btn
          active-class="nav-active"
          class="nav-button"
          large
          link
          plain
          to="/faq"
        >
          FAQ
        </v-btn>

        <v-btn
          active-class="nav-active"
          class="nav-contact-button gradient-button white--text"
          large
          link
          elevation="4"
          to="/booking"
        >
          <v-icon left>mdi-message-text</v-icon>
          Kontakt
        </v-btn>
      </div>
      <v-app-bar-nav-icon v-else @click.stop="sidenav = !sidenav" />
    </v-app-bar>

    <v-navigation-drawer
      v-model="sidenav"
      fixed
      right
      temporary
      class="sidenav"
    >
      <v-list dense nav>
        <v-list-item-group>
          <v-subheader class="my-3">
            <router-link to="/#top">
              <v-img
                max-height="100"
                src="@/assets/webp/logo.webp"
                width="200"
              ></v-img>
            </router-link>
          </v-subheader>

          <v-list-item
            v-for="item in navSideBarLinks"
            :key="item.title"
            :to="item.link"
            link
          >
            <v-list-item-icon>
              <v-icon :color="item.color" v-text="'mdi-' + item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-subheader class="nav-footer">
        <v-icon small> mdi-copyright</v-icon>
        {{ new Date().getFullYear() }} -&nbsp;
        <strong>Omega Virtual Reality</strong>
      </v-subheader>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "NavbarComponent",

  data: () => ({
    sidenav: false,
    navSideBarLinks: [
      {
        title: "Startseite",
        icon: "home",
        color: "primary",
        link: "/#top",
      },
      {
        title: "VR Games",
        icon: "gamepad-variant",
        color: "primary",
        link: "/vr/games",
      },
      {
        title: "Preise",
        icon: "currency-eur",
        color: "primary",
        link: "/pricing",
      },
      {
        title: "Über uns",
        icon: "account-multiple",
        color: "primary",
        link: "/about",
      },
      {
        title: "FAQ",
        icon: "information",
        color: "primary",
        link: "/faq",
      },
      {
        title: "Kontakt",
        icon: "message-text",
        color: "secondary",
        link: "/booking",
      },
    ],
  }),

  computed: {
    showMenuIcon() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
  },

  watch: {
    sideNavClick() {
      this.sidenav = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-button {
  font-size: 1.1em !important;
  font-weight: 700;
  margin-right: 8px;
}

.nav-contact-button {
  margin-left: 5px;
  border-radius: 30px;
  padding: 0 20px !important;
  transition: transform 0.2s ease;
  position: relative;
  z-index: 5;

  &:hover {
    transform: scale(1.05);
  }
}

.gradient-button {
  background-image: linear-gradient(90deg, #1bbcda, #b641fa);
  font-weight: 600;
  letter-spacing: 0.5px;
}

.nav-footer {
  opacity: 0.5;
  position: absolute;
  bottom: 0;
}

.sidenav {
  background-color: rgba(32, 31, 31, 0.98) !important;
}

.nav-img:focus-visible {
  outline: 0;
}

.booking-promo-card {
  border-left: 3px solid #b641fa;
}
</style>
