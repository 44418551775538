<template>
  <v-footer class="text-center" dark padless>
    <v-card flat tile width="100%" class="footer-card">
      <!-- Newsletter-Sektion entfernt -->

      <!-- Social Media -->
      <v-card-text class="social-media-section">
        <v-btn
          v-for="iconObj in socialMediaIcons"
          :key="iconObj.icon"
          :href="iconObj.link"
          class="mx-3"
          icon
          color="white"
          x-large
        >
          <v-icon size="28px">
            {{ iconObj.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <!-- Main Footer Content -->
      <v-card-text class="footer-content">
        <v-container>
          <v-row class="w-100" justify="center">
            <v-col cols="12" lg="3" md="6" sm="6">
              <div class="my-3">
                <h3 class="mb-4">Schnellzugriff</h3>
                <v-list dark flat class="transparent">
                  <v-list-item-group color="primary">
                    <v-list-item to="/#top" dense>
                      <v-list-item-icon>
                        <v-icon>mdi-home</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Startseite</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/vr/games" dense>
                      <v-list-item-icon>
                        <v-icon>mdi-gamepad-variant</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>VR Games</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/pricing" dense>
                      <v-list-item-icon>
                        <v-icon>mdi-currency-eur</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Preise & Angebote</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/booking" dense>
                      <v-list-item-icon>
                        <v-icon>mdi-calendar-check</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Jetzt Buchen</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6">
              <div class="my-3">
                <h3 class="mb-4">Informationen</h3>
                <v-list dark flat class="transparent">
                  <v-list-item-group color="primary">
                    <v-list-item to="/about" dense>
                      <v-list-item-icon>
                        <v-icon>mdi-information</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Über uns</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/faq" dense>
                      <v-list-item-icon>
                        <v-icon>mdi-frequently-asked-questions</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>FAQ</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/privacy_policy" dense>
                      <v-list-item-icon>
                        <v-icon>mdi-shield-check</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Datenschutz</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/imprint" dense>
                      <v-list-item-icon>
                        <v-icon>mdi-file-document</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Impressum</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6">
              <div class="my-3">
                <h3 class="mb-4">Kontakt</h3>
                <v-list dark flat class="transparent">
                  <v-list-item href="mailto:service@omegavr.de" dense>
                    <v-list-item-icon>
                      <v-icon>mdi-email</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ email }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item :href="'tel:' + phone" dense>
                    <v-list-item-icon>
                      <v-icon>mdi-phone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ phone }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    :href="
                      'https://wa.me/' +
                      whatsapp.replace(/\+/g, '').replace(/\s/g, '')
                    "
                    dense
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-whatsapp</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>WhatsApp</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item to="/booking" dense>
                    <v-list-item-icon>
                      <v-icon>mdi-message-text</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Kontaktformular</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6">
              <div class="my-3">
                <h3 class="mb-4">Standort</h3>
                <v-img
                  alt="Standort"
                  class="mx-auto location-image"
                  max-height="150"
                  style="cursor: pointer"
                  @click="overlay = true"
                  contain
                  src="@/assets/webp/bw.webp"
                  width="250px"
                ></v-img>
                <p class="mt-2 caption">
                  Wir kommen zu Ihnen mit unserer mobilen VR-Ausrüstung im
                  Großraum Stuttgart!
                </p>
                <v-overlay z-index="0" :value="overlay">
                  <v-btn fab color="primary" @click="overlay = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-img
                    alt="Standort"
                    class="mx-auto"
                    @click="overlay = false"
                    width="50%"
                    contain
                    src="@/assets/webp/bw.webp"
                  />
                </v-overlay>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <!-- Bottom Copyright -->
      <v-card-text class="copyright-section">
        <v-icon small style="top: -2px"> mdi-copyright </v-icon> Copyright
        {{ new Date().getFullYear() }} — <strong>Omega Virtual Reality</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    email: "service@omegavr.de",
    phone: "+49 1633781482",
    whatsapp: "+49 1633781482",
    overlay: false,
    socialMediaIcons: [
      {
        icon: "mdi-instagram",
        link: "https://www.instagram.com/omegavr.de/",
      },
      {
        icon: "$tiktok",
        link: "https://www.tiktok.com/@omegavr",
      },
      {
        icon: "mdi-facebook",
        link: "https://www.facebook.com/Omega-Virtual-Reality-103708545829435",
      },
      {
        icon: "mdi-youtube",
        link: "#",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.footer-card {
  background-color: #1a1a1a;
}

/* Newsletter-Styling entfernt */

.social-media-section {
  background-color: #252525;
  padding: 20px 0;
}

.footer-content {
  background-color: #1a1a1a;
  padding-top: 30px;
  padding-bottom: 30px;
}

.copyright-section {
  background-color: #121212;
  padding: 15px 0;
}

.gradient-button {
  background-image: linear-gradient(90deg, #1bbcda, #b641fa);
  color: white !important;
  font-weight: 600;
}

.location-image {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

::v-deep .v-text-field--filled .v-text-field__slot {
  background-color: rgba(255, 255, 255, 0.1);
}

.v-list-item--dense {
  min-height: 32px !important;
}
</style>
